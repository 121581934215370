import React from 'react';

const locales = {
	en: {
		Add: "Add",
        AuthFailed: 'The sign in request could not be completed.',
		Auto: "Auto",
		ConfigChanged: "Config changed. Do you want to save?",
		Delete: "Delete",
        Loading: 'Loading...',
		Manual: "Manual",
		Recipients: "Recipients",
		Save: "Save",
		Selected: "Selected",
		SignInRequest: 'Processing sign-in request...',
        SignInDifferentAccount: 'Sign in with a different account',
        SignOut: 'Sign out',
		SitesAndLibraries: "Sites & document libraries",
		SitesLibrariesAutomaticReport: "Sites & libraries that will receive an automatic report",
		SitesLibrariesManualReport: "Sites & libraries that will receive a manual report",
        TryAgain: 'Try again',
        UnauthorizedMessage: 'You are not authorized to access the application.',
		Users: "Users"
	},
	nl: {
		Add: "Voeg toe",
        AuthFailed: 'Het aanmeldingsverzoek kan niet worden voltooid.',
		Auto: "Auto",
		ConfigChanged: "Configuratie veranderd. Wil je opslaan?",
		Delete: "Verwijderen",
        Loading: 'Docubird...',
		Manual: "Handmatig",
		Recipients: "Ontvangers",
		Save: "Opslaan",
		Selected: "Geselecteerde",
        SignInRequest: 'Het inlogverzoek verwerken...',
        SignInDifferentAccount: 'Login met een ander account',
        SignOut: 'Afmelden',
		SitesAndLibraries: "Sites en documentbibliotheken",
		SitesLibrariesAutomaticReport: "Sites en bibliotheken die automatisch een rapport ontvangen",
		SitesLibrariesManualReport: "Sites en bibliotheken die een handmatig rapport zullen ontvangen",
        TryAgain: 'Probeer het opnieuw',
        UnauthorizedMessage: 'U heeft geen toegang tot de applicatie.',
		Users: "Gebruikers"
	},
};

export const LanguageContext = React.createContext(locales.en);

export class LocalizationModule {
	static getLocaleStrings() {
		const localeLang = navigator.language?.toLowerCase()?.substring(0, 2) ?? '';

		switch (localeLang) {
			case 'en':
				return locales.en;
			case 'nl':
				return locales.nl;
			default:
				return locales.en;
		}
	}
}
