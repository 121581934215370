import axios from 'axios';


const apiUrl = process.env.REACT_APP_BASE_API;
const drApiKey = process.env.REACT_APP_DR_API_KEY;

export interface ReportConfig {
    sites: ReportSite[];
    recipients: string[];
    previousExecutionDate: Date
}

export interface ReportSite {
    id: string;
    webUrl: string;
    subSites: ReportSite[];
    libraries: ReportLibrary[];
    siteName: string;
}

export interface ReportLibrary {
    id: string;
    title: string;
}

const config = {
    headers:{
      "DR-API-KEY": drApiKey,
      'Content-Type': 'application/json',
    }
  };

export const getTenant = async (): Promise<string> => {
    return (await axios.get(`${apiUrl}/report/tenant`, config)).data;
};

export const getReportConfig = async (): Promise<ReportConfig> => {
    return (await axios.get(`${apiUrl}/report/config`, config)).data;
};

export const updateReportConfig = async (reportConfig: ReportConfig): Promise<ReportConfig> => {
    reportConfig.previousExecutionDate = new Date()
    return (
        await axios.put(`${apiUrl}/report/update`, reportConfig, config)
    );
};

export const genrateReport = async (reportConfig: ReportConfig): Promise<ReportConfig> => {
    reportConfig.previousExecutionDate = new Date();
    return (
        await axios.post(`${apiUrl}/report/generate`, reportConfig, config)
    );
};