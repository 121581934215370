import React from 'react';
import { LanguageContext } from '../utilities/LocalizationModule';
import { INavStyles, Icon, Nav } from '@fluentui/react';
import { ReportConfig, ReportLibrary, ReportSite } from '../services/reportService';
import { INavLinkCustom } from '../interfaces/INavLinkCustom';
import { ItemTypeEnum } from '../enums/ItemTypeEnum';
import { INavLinkGroupCustom } from '../interfaces/INavLinkGroupCustom';

const navStyles: Partial<INavStyles> = {
    root: {
        height: "calc(100vh - 50px)",
        borderRight: '1px solid #eee',
        overflowY: 'auto',
    },
};

const navStylesConfigChanged: Partial<INavStyles> = {
    root: {
        height: "calc(100vh - 90px)",
        borderRight: '1px solid #eee',
        overflowY: 'auto',
    },
};

interface ICurrentSelectionProps {
    currentReportConfig: ReportConfig;
    configChanged: boolean;
};

const CurrentSelection: React.FC<ICurrentSelectionProps> = ({ currentReportConfig, configChanged }: ICurrentSelectionProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const [reportNavLinks, setReportNavLinks] = React.useState<INavLinkGroupCustom[]>([{ name: `${languageStrings.Selected} ${languageStrings.SitesAndLibraries}`, links: [] }]);

    React.useEffect(() => {
        if (currentReportConfig && currentReportConfig.sites) {
            const newReportNavLinks = convertReportConfigToNavLinks(currentReportConfig);
            setReportNavLinks(newReportNavLinks);
        }
    }, [currentReportConfig?.sites]);

    function convertReportConfigToNavLinks(reportConfig: ReportConfig): INavLinkGroupCustom[] {
        const navLinkGroups: INavLinkGroupCustom[] = [
          {
            name: `${languageStrings.Selected} ${languageStrings.SitesAndLibraries}`,
            links: reportConfig.sites.map((site) => convertReportSiteToNavLink(site)),
          },
        ];
        return navLinkGroups;
      }
      
      function convertReportSiteToNavLink(site: ReportSite): INavLinkCustom {
        const subsitesLinks: INavLinkCustom[] = site.subSites
          ? site.subSites.map((subsite) => convertReportSiteToNavLink(subsite))
          : [];
      
        const librariesLinks: INavLinkCustom[] = site.libraries
          ? site.libraries.map((library) => convertReportLibraryToNavLink(library))
          : [];
      
        const links: INavLinkCustom[] = [...subsitesLinks, ...librariesLinks];
      
        return {
          key: site.id,
          name: site.siteName,
          url: "",
          itemType: ItemTypeEnum.Site,
          webUrl: site.webUrl,
          isExpanded: true,
          links: links.length > 0 ? links : undefined,
          checked: true,
        };
      }
      
      
      function convertReportLibraryToNavLink(library: ReportLibrary): INavLinkCustom {
        return {
          key: library.id,
          name: library.title,
          url: "",
          itemType: ItemTypeEnum.DocLibrary,
          isExpanded: true,
          webUrl: "", 
          checked: true,
        };
      }

    const onRenderLink = (link: INavLinkCustom) => {

        const getItemIcon = (itemType: ItemTypeEnum) => {
            switch (itemType) {
                case ItemTypeEnum.Site:
                case ItemTypeEnum.SubSite:
                    return (
                        <span className='pr-2'>
                            <img
                                src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/svg/sharepoint_48x1.svg"
                                width="20"
                                height="20"
                                alt="SharePoint subsite icon"
                            />
                        </span>
                    );
                case ItemTypeEnum.DocLibrary:
                    return <Icon iconName={"FolderHorizontal"} className="mr-2" />
                default:
                    return "Info";
            }
        };

        return (
            <div className="flex justify-between items-center w-full">
                <div className="flex items-center">
                    {getItemIcon(link.itemType)}
                    <span className="align-middle">{link.name}</span>
                </div>
            </div>
        );

    }

    return (
        <>
            {currentReportConfig && <Nav
                ariaLabel="Report Nav example"
                groups={reportNavLinks}
                styles={configChanged ? navStylesConfigChanged : navStyles}
                onRenderLink={onRenderLink}
                selectedKey=''
            />}
        </>

    )
}

export default CurrentSelection;