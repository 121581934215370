import { FontSizes, FontWeights, IButtonStyles, IIconStyles, ILabelStyles } from "@fluentui/react"
import { CSSProperties } from "react"

export const emailStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.mediumPlus,
        marginBottom: 0,
        marginRight: 8,
        color: "white"
    }
}

export const titleStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.xLarge,
        marginBottom: 0,
        marginLeft: 15,
        color: "white"
    }
}

export const signOutButtonStyle: Partial<IButtonStyles> = {
    root: {
        marginRight: "1vw",
        fontSize: FontSizes.size16,
        color: "white",
    }
}

export const signOutIconStyle: Partial<IIconStyles> = {
    root: {
        color: "white",
    }
}

export const settingsIconStyle: Partial<IIconStyles> = {
    root: {
        color: "white",
        fontSize: 20
    }
}

export const navContainerStyle: CSSProperties = {
    height: "50px",
    backgroundColor: "#484644"//theme.palette.themeDarkAlt,
}

export const logoStyle: CSSProperties = {
    marginLeft: 25,
}

