import { IPanelStyles, IPersonaProps, Panel, PanelType, Persona, PersonaSize } from "@fluentui/react";
import React from "react";
import { LanguageContext } from "../utilities/LocalizationModule";

const panelStyles: Partial<IPanelStyles> = {
    content: {
        overflowX: "hidden",
        touchAction: "true",
        height: "calc(100vh - 60px);",
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 0,
    },
};
export interface ISelectUserPanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
    people: IPersonaProps[];
    selectedPeople: IPersonaProps[];
    selectedUser: (person: IPersonaProps) => void;
}

export const SelectUserPanel: React.FunctionComponent<ISelectUserPanelProps> = ({ isOpen, dismissPanel, people, selectedUser, selectedPeople }: ISelectUserPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);

    return (
        <Panel
            headerText={languageStrings.Users}
            isOpen={isOpen}
            onDismiss={dismissPanel}
            closeButtonAriaLabel="Close"
            styles={panelStyles}
            type={PanelType.custom}
            customWidth={"480px"}
            allowTouchBodyScroll
        >
            <div>
                {people.filter((person) => !selectedPeople.some((selectedPerson) => selectedPerson.id === person.id)).map((person, index) => (
                    <div className="py-1 px-2 hover:bg-gray-100 hover:cursor-pointer" onClick={() => selectedUser(person)} key={index}>
                        <Persona
                            key={person.id}
                            text={person.text}
                            secondaryText={person.optionalText}
                            tertiaryText={person.tertiaryText}
                            optionalText={person.secondaryText}
                            size={PersonaSize.size40}
                        />
                    </div>

                ))}
            </div>
        </Panel>
    );
};
