import React from 'react';
import { Spinner, SpinnerSize } from "@fluentui/react";

interface LoadingProps {
    label: string,
    size?: SpinnerSize
};

const Loading: React.FC<LoadingProps> = ({
    label,
    size = SpinnerSize.medium,
}: LoadingProps) => {
    return <div className="xb-loader-container"><Spinner label={label} size={size} /></div>;
}

export default Loading;