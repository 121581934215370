import React from 'react';
import { LanguageContext } from '../utilities/LocalizationModule';
import { ActionButton, IIconProps, Stack, Label, IStackTokens, Separator } from '@fluentui/react';
import * as TopNavStyle from "../styles/TopNavigatorStyle"

interface TopNavProps {
    email: string,
    signOut: () => void,
};

const logOutIcon: IIconProps = { iconName: 'SignOut', styles: TopNavStyle.signOutIconStyle };
const rightElementTokens: IStackTokens = { childrenGap: 15 };

const TopNav: React.FC<TopNavProps> = ({ email, signOut }: TopNavProps) => {
    const languageStrings = React.useContext(LanguageContext);

    return (
        <Stack style={TopNavStyle.navContainerStyle} horizontal horizontalAlign="space-between" verticalAlign="center">
            <Stack.Item>
                <Label styles={TopNavStyle.titleStyle}>
                    Drenthe Reporting
                </Label>
            </Stack.Item>

            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={rightElementTokens}>
                    <Stack.Item>
                        <Label styles={TopNavStyle.emailStyle}>{email}</Label>
                    </Stack.Item>
                    <Separator vertical className="lighterSeparator" />
                    <Stack.Item>
                        <ActionButton className="darkHover" primary iconProps={logOutIcon} text={languageStrings.SignOut} styles={TopNavStyle.signOutButtonStyle} onClick={signOut} />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

export default TopNav;