import React from 'react';
import logo from './logo.svg';
import './App.css';
import { LanguageContext, LocalizationModule } from './utilities/LocalizationModule';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { DefaultButton } from '@fluentui/react';
import Loading from './components/Loading';
import MessageScreen from './components/MessageScreen';
import { authRequest } from './utilities/authConfig';
import Home from './pages/Home';

const AuthLoading: React.FC = () => {
  const languageStrings = React.useContext(LanguageContext);
  return (
    <div className='h-screen flex items-center justify-center'>
      <Loading label={languageStrings.SignInRequest} />
    </div>
  )

};

const AuthError: React.FC = () => {
  const languageStrings = React.useContext(LanguageContext);
  const actionButton = <DefaultButton style={{ marginTop: 10 }}
    onClick={() => {
      window.location.reload();
    }}>
    {languageStrings.TryAgain}
  </DefaultButton>
  return <MessageScreen text={languageStrings.AuthFailed} actionButton={actionButton} />;
};

const App: React.FC = () => {
  const languageStrings = LocalizationModule.getLocaleStrings();

  return (
    <LanguageContext.Provider value={languageStrings}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={AuthError}
        loadingComponent={AuthLoading}
      >
        <Home />
        {/* <Home /> */}
      </MsalAuthenticationTemplate>

    </LanguageContext.Provider >

  );
}

export default App;
