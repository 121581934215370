import React from 'react';
import { LanguageContext } from '../utilities/LocalizationModule';
import { Checkbox, ILabelStyles, INavStyles, IStyleSet, Icon, Label, Link, Nav, Pivot, PivotItem, Spinner, SpinnerSize } from '@fluentui/react';
import { ItemTypeEnum } from '../enums/ItemTypeEnum';
import { INavLinkGroupCustom } from '../interfaces/INavLinkGroupCustom';
import { INavLinkCustom } from '../interfaces/INavLinkCustom';
import { ReportConfig, ReportLibrary, ReportSite } from '../services/reportService';
import { ReportingModeEnum } from '../enums/ReportingModeEnum';
import { fetchDocumentLibraries, fetchSharePointSites, fetchSubsites } from '../services/sharepointService';
import Loading from './Loading';

const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: { marginTop: 10 },
};

const pivotStyles = {
    root: {
        borderBottom: '1px solid rgb(237, 235, 233);',
        height: 49
    },
};

interface IDirectoryBrowserProps {
    accessToken: string;
    originalReportConfig: ReportConfig;
    setCurrentReportConfigSites: (sites: ReportSite[]) => void;
    setConfigChanged: (configChanged: boolean) => void;
    genarateManualReport: () => void;
    setReportingMode: (reportingMode: ReportingModeEnum) => void;
    reportingMode: ReportingModeEnum;
    configChanged: boolean;
};

export interface SimplifiedNavLinkGroup {
    links: SimplifiedNavLink[];
}

export interface SimplifiedNavLink {
    key: string;
    links: SimplifiedNavLink[];
    webUrl?: string;
    itemType: ItemTypeEnum;
}

const DirectoryBrowser: React.FC<IDirectoryBrowserProps> = ({ accessToken, originalReportConfig, setCurrentReportConfigSites, setConfigChanged, genarateManualReport, setReportingMode, reportingMode, configChanged }: IDirectoryBrowserProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const [sites, setSites] = React.useState<any[]>([]);
    const [navLinkGroups, setNavLinkGroups] = React.useState<INavLinkGroupCustom[]>([{ name: languageStrings.SitesAndLibraries, links: [] }]);
    const [isManualGenerating, setIsManualGenerating] = React.useState(false);
    const [isLoading, setIsloading] = React.useState(true);

    const [navStylesSites, setNavStylesSites] = React.useState<Partial<INavStyles>>();

    React.useEffect(() => {
        let height = "185px";

        if (reportingMode === ReportingModeEnum.Auto && configChanged) {
            height = "200px";
        }
        else if (reportingMode === ReportingModeEnum.Auto && !configChanged) {
            height = "165px";
        }
        else if (reportingMode === ReportingModeEnum.Manual) {
            height = "200px";
        }

        const style: Partial<INavStyles> = {
            root: {
                height: `calc(100vh - ${height})`,
                borderRight: '1px solid #eee',
                overflowY: 'auto',
            },
        }

        setNavStylesSites(style);


    }, [reportingMode, configChanged]);

    React.useEffect(() => {
        if (accessToken) {
            (async () => {
                setSites(await fetchSharePointSites(accessToken));
            })();
        }
    }, [accessToken]);

    React.useEffect(() => {
        const updatedConfigSites = convertNavLinksToReportConfig(navLinkGroups);
        setCurrentReportConfigSites(updatedConfigSites);
    }, [navLinkGroups]);

    React.useEffect(() => {
        const initializeNavLinks = async () => {
            setIsloading(true);
            const links = await constructNavLinks(sites, originalReportConfig.sites);
            setNavLinkGroups([{ name: languageStrings.SitesAndLibraries, links }]);
            setIsloading(false);
        };

        if (sites?.length > 0 && originalReportConfig) {
            initializeNavLinks();
        }
    }, [originalReportConfig, sites]);

    function convertNavLinksToReportConfig(navLinkGroups: INavLinkGroupCustom[]): ReportSite[] {
        const reportSites: ReportSite[] = navLinkGroups
            .flatMap((group) => group.links)
            .filter((link) => link.checked)
            .map(convertNavLinkToReportSite);

        return reportSites;
    }

    function convertNavLinkToReportSite(link: INavLinkCustom): ReportSite {
        const subsites: ReportSite[] = link.links
            ? link.links
                .filter((sublink) => sublink.checked && sublink.itemType !== ItemTypeEnum.DocLibrary)
                .map(convertNavLinkToReportSite)
            : [];

        const libraries: ReportLibrary[] = link.itemType === ItemTypeEnum.DocLibrary && link.checked
            ? [{ id: link.key, title: link.name }]
            : link.links
                ? link.links
                    .filter((sublink) => sublink.checked && sublink.itemType === ItemTypeEnum.DocLibrary)
                    .map((sublink) => ({ id: sublink.key, title: sublink.name }))
                : [];

        return {
            id: link.key,
            webUrl: link.webUrl,
            subSites: subsites,
            libraries: libraries,
            siteName: link.name,
        };
    }

    const constructNavLinks = async (
        sites: any[],
        reportSites: ReportSite[] = []
    ): Promise<INavLinkCustom[]> => {
        const navLinks: INavLinkCustom[] = [];

        for (const site of sites) {
            const reportSite = reportSites.find((rs) => rs.id === site.id);

            let subsiteLinks: INavLinkCustom[] = [];
            let docLibLinks: INavLinkCustom[] = [];

            if (reportSite) {

                const [subsites, docLibs] = await Promise.all([
                    fetchSubsites(site.id, accessToken),
                    fetchDocumentLibraries(site.id, accessToken),
                ]);
    
                subsiteLinks = await constructNavLinks(subsites, reportSite?.subSites || []);

                
                docLibLinks = docLibs.map((lib) => {
                    const library = reportSite?.libraries.find((l) => l.id === lib.id);
                    return {
                        key: lib.id,
                        name: lib.name,
                        url: "",
                        itemType: ItemTypeEnum.DocLibrary,
                        webUrl: "",
                        links: [],
                        checked: !!library,
                    };
                });
            }

            navLinks.push({
                key: site.id,
                name: site.displayName,
                url: "",
                itemType: ItemTypeEnum.Site,
                webUrl: site.webUrl,
                links: [...subsiteLinks, ...docLibLinks],
                checked: !!reportSite,
            });
        }

        return navLinks;
    };

    const _onLinkClick = async (ev: any, link: INavLinkCustom) => {
        if ((!link.links || link.links?.length === 0) && (link.itemType === ItemTypeEnum.Site || link.itemType === ItemTypeEnum.SubSite)) {
            link.isLoading = true;
    
            const subsites = await fetchSubsites(link.key, accessToken);
            const docLibraries = await fetchDocumentLibraries(link.key, accessToken);
    
            const updatedLinks: INavLinkCustom[] = [];

            for (const subsite of subsites) {
                updatedLinks.push({
                    key: subsite.id,
                    name: subsite.displayName,
                    itemType: ItemTypeEnum.SubSite,
                    url: "#",
                    webUrl: subsite.webUrl,
                    links: undefined,
                    isLoading: false,
                    checked: false,
                });
            }
    
            for (const docLibrary of docLibraries) {
                updatedLinks.push({
                    key: docLibrary.id,
                    name: docLibrary.name,
                    itemType: ItemTypeEnum.DocLibrary,
                    url: "#",
                    webUrl: docLibrary.webUrl,
                    links: undefined,
                    isLoading: false,
                    checked: false,
                });
            }
    
            link.links = [...updatedLinks];
            link.isExpanded = true;
            link.isLoading = false;
            setNavLinkGroups([...navLinkGroups]);
        }
    };
    

    const onRenderLink = (link: INavLinkCustom): JSX.Element => {
       
        const getItemIcon = (itemType: ItemTypeEnum) => {
            switch (itemType) {
                case ItemTypeEnum.Site:
                    return "Globe";
                case ItemTypeEnum.SubSite:
                    return "Globe2";
                case ItemTypeEnum.DocLibrary:
                    return "FolderHorizontal";
                default:
                    return "Info";
            }
        };
    
        const onCheckboxWrapperClick = (ev: React.MouseEvent<HTMLElement>) => {
            ev.stopPropagation();
        };
    
        return (
            <div className="flex justify-between items-center w-full">
                <div className="flex items-center">
                    <Icon iconName={getItemIcon(link.itemType)} className="mr-2" />
                    <span>{link.name}</span>
                    {link.isLoading && (
                        <div className="ml-2">
                            <Spinner size={SpinnerSize.small} />
                        </div>
                    )}
                </div>
                <div onClick={onCheckboxWrapperClick}>
                    <Checkbox
                        className="ml-2"
                        checked={link.checked}
                        onChange={(ev, isChecked) => {
                            onCheckboxChange(link, isChecked);
                        }}
                    />
                </div>
            </div>
        );
    };
    

    const onCheckboxChange = (link: INavLinkCustom, isChecked: boolean) => {
        setNavLinkGroups((prevState) => {
            
            const newGroups = JSON.parse(JSON.stringify(prevState)); // Deep copy the state to avoid direct state mutation
            const targetLink = findLink(newGroups[0].links, link.key);

            if (targetLink) {
                targetLink.checked = isChecked;
            }
            return newGroups;
        });
        setConfigChanged(true);
    };

    const findLink = (links: INavLinkCustom[], key: string): INavLinkCustom | undefined => {
            for (const link of links) {
                if (link.key === key) {
                    return link;
                }
                if (link.links && link.links.length > 0) {
                    const found = findLink(link.links, key);
                    if (found) {
                        return found;
                    }
                }
            }
        return undefined;
    };


    const handlePivotItemClick = (item, ev) => {
        if (item.props.headerText === languageStrings.Auto) {
            setReportingMode(ReportingModeEnum.Auto)
        } else {
            setReportingMode(ReportingModeEnum.Manual)
        }
    };


    return (
        <div className='flex flex-col'>
            <Pivot aria-label="Basic Pivot Example" styles={pivotStyles} onLinkClick={handlePivotItemClick}>
                <PivotItem
                    headerText={languageStrings.Auto}
                    headerButtonProps={{
                        'data-order': 1,
                        'data-title': 'My Files Title',
                    }}
                >
                    <div className='p-2 text-center'>
                        <Label disabled styles={labelStyles}>{languageStrings.SitesLibrariesAutomaticReport}</Label>
                    </div>
                </PivotItem>
                <PivotItem headerText={languageStrings.Manual}>
                    <div className='p-2 text-center'>
                        <Label disabled styles={labelStyles}>{languageStrings.SitesLibrariesManualReport}</Label>
                        <div className='flex justify-center items-center'>
                            <Link disabled={isManualGenerating} onClick={async () => {
                                setIsManualGenerating(true);
                                await genarateManualReport();
                                setIsManualGenerating(false);
                            }}>Send</Link>
                            {isManualGenerating && <div className='pl-2'><Spinner size={SpinnerSize.small}></Spinner></div>}
                        </div>

                    </div>
                </PivotItem>
            </Pivot>

            {isLoading && <div className='w-full text-center pt-10'><Loading label={languageStrings.Loading} /></div>}
            {!isLoading && <Nav
                onLinkClick={_onLinkClick}
                selectedKey="key3"
                ariaLabel="Nav basic example"
                groups={navLinkGroups}
                onRenderLink={onRenderLink}
                styles={navStylesSites}
            />}

        </div>
    )
}

export default DirectoryBrowser;