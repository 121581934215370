import * as React from 'react';
import { LanguageContext } from '../utilities/LocalizationModule';
import { Checkbox, CommandBar, DefaultButton, ICommandBarItemProps, ILabelStyles, INavLink, INavLinkGroup, INavStyles, IPersonaProps, IStyleSet, Icon, Label, MessageBar, MessageBarButton, MessageBarType, Nav, NormalPeoplePicker, Persona, PersonaSize, Pivot, PivotItem, Spinner, SpinnerSize, Stack, mergeStyleSets } from "@fluentui/react"
import { useMsal } from '@azure/msal-react';
import Loading from '../components/Loading';
import TopNav from '../components/TopNav';
import MessageScreen from '../components/MessageScreen';
import { sitesScopes } from '../utilities/authConfig';
import { close } from 'inspector';
import { SelectUserPanel } from '../components/SelectUserPanel';
import { DocLibraryIcon } from '@fluentui/react-icons-mdl2';
import { ReportConfig, ReportLibrary, ReportSite, genrateReport, getReportConfig, getTenant, updateReportConfig } from '../services/reportService';
import { ItemTypeEnum } from '../enums/ItemTypeEnum';
import { INavLinkCustom } from '../interfaces/INavLinkCustom';
import { INavLinkGroupCustom } from '../interfaces/INavLinkGroupCustom';
import DirectoryBrowser, { SimplifiedNavLink, SimplifiedNavLinkGroup } from '../components/DirectoryBrowser';
import CurrentSelection from '../components/CurrentSelection';
import { ReportingModeEnum } from '../enums/ReportingModeEnum';

const commandBarStyles = {
  root: {
    height: 48, // Set your desired height here
    paddingLeft: 0
  },
};


const Home: React.FC = () => {
  const languageStrings = React.useContext(LanguageContext);

  const { instance, accounts } = useMsal();

  const [isUnauthorized, setIsUnauthorized] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [accessToken, setAccessToken] = React.useState("");

  const [people, setPeople] = React.useState<IPersonaProps[]>([]);
  const [selectedPeople, setSelectedPeople] = React.useState<IPersonaProps[]>([]);
  const [selectedPerson, setSelectedPerson] = React.useState<IPersonaProps>();
  const [isSelectUserPanelOpen, setIsSelectUserPanelOpen] = React.useState(false);
  const [commandBarItems, setCommandBarItems] = React.useState<ICommandBarItemProps[]>([]);

  const [configChanged, setConfigChanged] = React.useState(false);
  const [originalReportConfig, setOriginalReportConfig] = React.useState<ReportConfig>();
  const [currentReportConfig, setCurrentReportConfig] = React.useState<ReportConfig>();

  const [reportingMode, setReportingMode] = React.useState<ReportingModeEnum>(ReportingModeEnum.Auto)

  React.useEffect(() => {
    if (accounts.length > 0) {
      (async () => {
        try {
          setIsLoading(true);
          const response = await instance.acquireTokenSilent({ account: accounts[0], scopes: sitesScopes });
          if (response) {
            setAccessToken(response.accessToken);
            fetchUsers(response.accessToken);
            const tenantId = await getTenant();
            if (tenantId && accounts[0]?.tenantId?.toLowerCase() === tenantId.toLowerCase()) {
              setIsUnauthorized(false);
              setIsLoading(false);
            } else {
              setIsUnauthorized(true);
              setIsLoading(false);
            }
          }
        } catch (error) {
          setIsUnauthorized(true);
          setIsLoading(false);
        }
      })();
    }

  }, [accounts, instance]);

  React.useEffect(() => {
    if (!isUnauthorized) {
      (async () => {
        const reportConfig = await getReportConfig();
        setOriginalReportConfig(reportConfig);
        setCurrentReportConfig(reportConfig);
      })();
    }
  }, [isUnauthorized]);


  React.useEffect(() => {
    setCommandBarItems([
      {
        key: "Add",
        text: languageStrings.Add,
        iconProps: { iconName: "Add" },
        onClick: () => {
          setIsSelectUserPanelOpen(true);
        },
      },
      {
        key: "Delete",
        text: languageStrings.Delete,
        disabled: !selectedPerson,
        iconProps: { iconName: "Delete" },
        onClick: () => {
          setSelectedPeople([...selectedPeople].filter(x => x.id !== selectedPerson.id));
          setSelectedPerson(undefined);
          setConfigChanged(true);
        },
      },
    ]);
  }, [selectedPerson, selectedPeople]);

  const saveConfig = async () => {
    const results = await updateReportConfig(currentReportConfig);
    setConfigChanged(false);
  }

  const genarateManualReport = async () => {
    const results = await genrateReport(currentReportConfig);
  }

  const signOut = () => {
    instance.logoutRedirect();
  }

  const fetchUsers = async (accessToken) => {
    const endpoint = "https://graph.microsoft.com/v1.0/users";
    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();

    const usersToPersonas: IPersonaProps[] = data.value.map((user) => ({
      text: user.displayName,
      secondaryText: user.jobTitle,
      tertiaryText: user.officeLocation,
      optionalText: user.userPrincipalName,
      id: user.id,
    }));

    setPeople(usersToPersonas);

    return data.value;
  };

  React.useEffect(() => {
    if (people.length > 0 && originalReportConfig) {
      const selected = originalReportConfig.recipients.map((recipient) => {
        const person = people.find((p) => p.optionalText === recipient);
        return person ? { ...person } : undefined;
      }).filter((p) => p !== undefined) as IPersonaProps[];

      setSelectedPeople(selected);
    }
  }, [people, originalReportConfig]);

  React.useEffect(() => {
    const updatedConfig = {
      ...currentReportConfig,
      recipients: selectedPeople.map(person => person.optionalText)
    }

    setCurrentReportConfig(updatedConfig);

  }, [selectedPeople])

  const setCurrentReportConfigSites = (sites: ReportSite[]) => {
    if (currentReportConfig) {
      const updatedConfig = {
        ...currentReportConfig,
        sites: sites,
      }

      setCurrentReportConfig(updatedConfig);
    }
  }

  return (
    <>
      {isLoading &&
        <div className='h-screen flex items-center justify-center'>
          <Loading label={languageStrings.Loading} />
        </div>}

      {!isLoading && isUnauthorized &&


        <MessageScreen
          text={languageStrings.UnauthorizedMessage}
          actionButton={
            <DefaultButton style={{ marginTop: 10 }} onClick={signOut}>
              {languageStrings.SignInDifferentAccount}
            </DefaultButton>
          }
        />

      }
      {!isLoading && !isUnauthorized && originalReportConfig &&
        <React.Fragment>
          <TopNav email={accounts[0]?.username} signOut={signOut} />
          {configChanged && reportingMode === ReportingModeEnum.Auto && <div className='p-1'>

            <MessageBar
              actions={
                <div>
                  <MessageBarButton onClick={() => saveConfig()}>{languageStrings.Save}</MessageBarButton>
                </div>
              }
              messageBarType={MessageBarType.warning}
              isMultiline={false}
            >
             {languageStrings.ConfigChanged}
            </MessageBar>
          </div>}
          <div className='flex'>
            <div className="w-1/4">
              {accessToken && originalReportConfig && <DirectoryBrowser accessToken={accessToken} originalReportConfig={originalReportConfig} setCurrentReportConfigSites={setCurrentReportConfigSites} setConfigChanged={setConfigChanged} genarateManualReport={genarateManualReport} setReportingMode={setReportingMode} reportingMode={reportingMode} configChanged={configChanged}/>}
            </div>

            <div className='grow border-l-gray-400 border-1'>
              {currentReportConfig && <CurrentSelection currentReportConfig={currentReportConfig} configChanged={configChanged}  />}
            </div>
            <div className="w-1/4">
              <div className='flex items-center text-xl font-normal text-gray-800 border-b border-b-gray-300 h-[49px] my-auto pl-2'>
                {languageStrings.Recipients}
              </div>
              <div className="border-b-2 border-b-gray-200">
                <CommandBar items={commandBarItems ?? []} styles={commandBarStyles} />
              </div>
              <div className={`${configChanged ? "p-2 h-[calc(100vh-190px)] overflow-auto" : "p-2 h-[calc(100vh-150px)] overflow-auto"}`}>
                {selectedPeople.slice().sort((a, b) => a.text.localeCompare(b.text)).map((person, index) => (
                  <div
                    className={`py-1 px-2 hover:bg-gray-100 hover:cursor-pointer ${person.id === selectedPerson?.id ? "bg-gray-200" : ""}`}
                    onClick={() => {
                      setSelectedPerson(person.id === selectedPerson?.id ? undefined : person);
                    }}
                    key={index}
                  >
                    <Persona
                      key={person.id}
                      text={person.text}
                      secondaryText={person.optionalText}
                      size={PersonaSize.size40}
                      className="selectedPerson"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          {isSelectUserPanelOpen && (
            <SelectUserPanel
              isOpen={isSelectUserPanelOpen}
              dismissPanel={() => {
                setIsSelectUserPanelOpen(false);
              }}
              people={people}
              selectedPeople={selectedPeople}
              selectedUser={(person) => {
                setSelectedPeople(prev => [...prev, person]);
                setIsSelectUserPanelOpen(false);
                setConfigChanged(true);
              }}
            />
          )}
        </React.Fragment>
      }
    </>)

}

export default Home;