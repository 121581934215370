export const fetchSharePointSites = async (accessToken: string) => {
    const endpoint = "https://graph.microsoft.com/v1.0/sites?search=* AND contentclass:STS_Site AND NOT WebTemplate:POLICYCTR AND NOT WebTemplate:POINTPUBLISHINGHUB AND NOT WebTemplate:POINTPUBLISHINGPERSONAL AND NOT WebTemplate:POINTPUBLISHINGTOPIC AND NOT WebTemplate:APPCATALOG AND NOT WebTemplate:SPSPERS AND NOT WebTemplate:TEAMCHANNEL AND NOT WebTemplate:PWA AND NOT WebTemplate:EDISC";
    const response = await fetch(endpoint, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (response.ok) {
        const data = await response.json();
        return data.value;
    } else {
        return [];
    }
};

export const fetchDocumentLibraries = async (siteId: string, accessToken: string) => {
    const docLibsEndpoint = `https://graph.microsoft.com/v1.0/sites/${siteId}/drives`;
    const response = await fetch(docLibsEndpoint, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (response.ok) {
        const data = await response.json();
        return data.value;
    } else {
        return [];
    }
};

export const fetchSubsites = async (siteId: string, accessToken: string) => {
    const subsitesEndpoint = `https://graph.microsoft.com/v1.0/sites/${siteId}/sites`;
    const response = await fetch(subsitesEndpoint, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (response.ok) {
        const data = await response.json();
        return data.value;
    } else {
        return [];
    }
};