import { FontWeights, getTheme, ILabelStyles, FontSizes } from "@fluentui/react";
import { CSSProperties } from "react";
const theme = getTheme()

export const messageContainer: CSSProperties = {
    height: "30vh",
    width: "20vw",
    minWidth: 280,
    borderRadius: 10,
    backgroundColor: theme.palette.themeDarkAlt,
    padding: 40
}

export const messageLabel: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.regular,
        fontSize: FontSizes.mediumPlus,
        color: theme.palette.white,
        wrapFlow: "both",
        textAlign: "center",
        marginBottom: 30
    }
}

export const logo: CSSProperties = {
    marginTop: 0,
}

export const titleStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.xLarge,
        marginBottom: 0,
        marginLeft: 15,
        color: "white"
    }
}